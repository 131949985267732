import { IPurchaseRequest } from "@/utils/interfaces/IPurchaseRequest";
import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";

interface PurchaseRequestsState {
  purchaseRequests: IPurchaseRequest[];
  requestEntities: [];
  requestToEdit: IPurchaseRequest[];
  areRequestsFetched: boolean;
  isPrCreated: boolean;
  isEditingPr: boolean;
  isRenewingPr: boolean;
}

interface PurchaseRequestsContext {
  commit: Commit;
  state: PurchaseRequestsState;
  dispatch: Dispatch;
}

const state: PurchaseRequestsState = {
  purchaseRequests: [],
  requestEntities: [],
  requestToEdit: [],
  areRequestsFetched: false,
  isPrCreated: false,
  isEditingPr: false,
  isRenewingPr: false,
};

const mutations = {
  setPurchaseRequests(state: PurchaseRequestsState, payload: IPurchaseRequest[]) {
    state.purchaseRequests = payload;
  },
  setRequestEntities(state: PurchaseRequestsState, payload: []) {
    state.requestEntities = payload;
  },
  setAreRequestsFetched(state: PurchaseRequestsState, areFetched: boolean) {
    state.areRequestsFetched = areFetched;
  },
  setIsPrCreated(state: PurchaseRequestsState, isPrCreated: boolean) {
    state.isPrCreated = isPrCreated;
  },
  setIsEditingPr(state: PurchaseRequestsState, isEditingPR: boolean) {
    state.isEditingPr = isEditingPR;
  },
  setIsRenewingPr(state: PurchaseRequestsState, isRenewingPr: boolean) {
    state.isRenewingPr = isRenewingPr;
  },
};

const getters = {
  getPurchaseRequests: (state: PurchaseRequestsState) => state.purchaseRequests,
  getRequestEntities: (state: PurchaseRequestsState) => state.requestEntities,
  getAreRequestsFetched: (state: PurchaseRequestsState) => state.areRequestsFetched,
  getIsPrCreated: (state: PurchaseRequestsState) => state.isPrCreated,
  getIsEditingPr: (state: PurchaseRequestsState) => state.isEditingPr,
  getIsRenewingPr: (state: PurchaseRequestsState) => state.isRenewingPr,
};

const actions = {
  fetchPurchaseRequests(context: PurchaseRequestsContext) {
    AXIOS_REQUEST("GET", "/purchaserequests")
      .then((response) => {
        let purchaseRequestsDO: IPurchaseRequest[] = [];
        for (const id in response.data) {
          purchaseRequestsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        purchaseRequestsDO = purchaseRequestsDO.sort((a: IPurchaseRequest, b: IPurchaseRequest) => {
          if (b.id !== undefined && a.id !== undefined) {
            return b.id - a.id;
          }
          return 0;
        });
        context.commit("setPurchaseRequests", purchaseRequestsDO);
        context.commit("setAreRequestsFetched", true);
      })
      .catch((error) => {
        console.error(error);

        if (error.response.status === 403) {
          context.dispatch("updateRequestMessage", `ERROR: You don't have permission to use this feature.`);
        }
      });
  },

  createPurchaseRequest(context: PurchaseRequestsContext, requestData: IPurchaseRequest) {
    AXIOS_REQUEST("POST", "/purchaserequests", requestData)
      .then((response) => {
        context.commit("setPurchaseRequests", [response.data]);
        context.commit("setIsPrCreated", true);
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error creating request: ${error}`);
      });
  },

  updatePurchaseRequestStatus(
    context: PurchaseRequestsContext,
    payload: {
      prID: number;
      status: string;
      isJustification: boolean;
      justification_status?: string;
      justified_by_name?: string;
      justification_comment?: string;
      justification_date?: string;
    }
  ) {
    if (payload.isJustification) {
      AXIOS_REQUEST("PATCH", `/purchaserequests/${payload.prID}`, {
        status: payload.status,
        justification_status: payload.justification_status,
        justified_by_name: payload.justified_by_name,
        justification_comment: payload.justification_comment,
        justification_date: payload.justification_date,
      }).catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error updating request: ${error}`);
      });
    } else {
      AXIOS_REQUEST("PATCH", `/purchaserequests/${payload.prID}`, {
        status: payload.status,
      }).catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error updating request: ${error}`);
      });
    }
  },

  updatePurchaseRequest(
    context: PurchaseRequestsContext,
    payload: {
      prID: number;
      deliveryDate: Date;
      prownername: string;
      data: IPurchaseRequest;
    }
  ) {
    AXIOS_REQUEST("PUT", `/purchaserequests/${payload.prID}`, [
      {
        id: payload.prID,
        podate: payload.data.podate,
        supplier: payload.data.supplier,
        ordertext: payload.data.ordertext,
        sbu: payload.data.sbu,
        orderamount: payload.data.orderamount,
        completionrate: null,
        accountingelement: payload.data?.accountingelement,
        deliverydate: payload.deliveryDate,
        status: payload.data.status,
        comments: payload.data.comments,
        currency: payload.data.currency,
        owner: payload.prownername,
        message: payload.data.message,
      },
    ])
      .then((response) => {
        if (context.state.purchaseRequests && response?.data && response.data.length > 0) {
          const index = context.state.purchaseRequests.findIndex((pr) => pr.id === payload.prID);
          if (index !== -1) {
            context.state.purchaseRequests[index] = response.data[0];
            context.dispatch("updateRequestMessage", context.state.purchaseRequests);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error updating request: ${error}`);
      });
  },

  declinePurchaseRequest(context: PurchaseRequestsContext, payload: { itemId: string; message?: string }) {
    AXIOS_REQUEST("DELETE", `/purchaserequests/${payload.itemId}`, {
      message: payload.message,
    })
      .then(() => {
        const updatedPurchaseRequests = context.state.purchaseRequests?.filter((request: IPurchaseRequest) => Number(request.id) !== Number(payload.itemId));
        context.commit("setPurchaseRequests", updatedPurchaseRequests);
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error declining request: ${error}`);
      });
  },

  switchToPurchaseOrder(context: PurchaseRequestsContext, payload: { itemId: number; orderNumber: string; customerContact: string }) {
    AXIOS_REQUEST("PUT", `/purchaserequests/switchtopurchaseorder/${payload.itemId}`, {
      ordernumber: payload.orderNumber,
      customercontact: payload.customerContact,
    })
      .then(() => {
        const updatedPurchaseRequests = context.state.purchaseRequests?.filter((request: IPurchaseRequest) => Number(request.id) !== payload.itemId);
        context.commit("setPurchaseRequests", updatedPurchaseRequests);
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error accepting request: ${error}`);
      });
  },

  changeRequestEntities(context: PurchaseRequestsContext, entities: string[]) {
    context.commit("setRequestEntities", entities);
  },

  deletePurchaseRequest(context: PurchaseRequestsContext, id: number) {
    AXIOS_REQUEST("DELETE", `/purchaserequests/delete/${id}`).catch((error) => {
      console.error(error);
    });
  },
};

const purchaseRequestsModule = {
  state,
  getters,
  mutations,
  actions,
};

export default purchaseRequestsModule;
