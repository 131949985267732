<template>
  <div class="cst-new-product-type--wrapper" v-if="futureBudget.length > 0" data-testid="budgetrequestreview-wrapper">
    <div class="cst-inputbox" :class="{ 'c-budgetrequestreview--overview': props.isoverview }" data-testid="budgetrequestreview-inputbox">
      <div class="cst-inputbox--header">
        <div class="cst-inputbox--header__title" data-testid="budgetrequestreview-inputbox-header">
          {{ props.isoverview ? `Approved Future ${financialGoalLabel} Entities` : "Requests In Review" }}
        </div>
      </div>
      <C_Search @input="filterFutureBudgetPlans($event)" :placeholder="`Search future ${financialGoalLabel}...`" />
      <div
        class="c-budgetrequestreview--wrapper"
        data-testid="budgetrequestreview-card-wrapper"
        :class="{
          'c-budgetrequestreview--wrapper--overview': props.isoverview,
        }"
      >
        <div class="c-budgetrequestreview" v-for="entity in filteredBudget" :key="entity.id">
          <C_BudgetCard @future-budget-details="handleFutureBudgetDetails" @future-budget-approve="handleFutureBudgetApprove" @future-budget-decline="handleFutureBudgetDecline" :entity="entity" :isoverview="props.isoverview" :financialGoalLabel="financialGoalLabel" />
        </div>
        <div class="c-budgetrequestreview" v-if="filteredBudget.length === 0" data-testid="budgetrequestreview-no-such-entities">There are no such entities.</div>
      </div>
    </div>
  </div>
  <div v-else-if="isoverview && isBudgetFetched" class="c-budgetrequestreview--empty" data-testid="budgetrequestreview-empty">No approved future budget requests available.</div>
  <div v-else-if="!isBudgetFetched" data-testid="budgetrequestreview-spinner" class="cst-spinner"></div>
  <C_FutureBudgetModal v-if="showDetailsModal" @close-details-modal="showDetailsModal = !showDetailsModal" :entity="selectedEntity" :financialGoalLabel="financialGoalLabel"></C_FutureBudgetModal>
  <C_FutureBudgetDecisionModal v-if="showDecisionModal" :entity="selectedEntity" @close-decision-modal="showDecisionModal = !showDecisionModal" :is-approval="isApproval" :financialGoalLabel="financialGoalLabel"> </C_FutureBudgetDecisionModal>
</template>
<script lang="ts" setup>
  import { computed, ref, defineProps, watch } from "vue";
  import { useStore } from "vuex";
  import C_BudgetCard from "./cards/C_BudgetCard.vue";
  import C_FutureBudgetModal from "./modals/C_FutureBudgetModal.vue";
  import C_FutureBudgetDecisionModal from "./modals/C_FutureBudgetDecisionModal.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { IBudgetPlan } from "@/utils/interfaces/IBudgetPlan";
  import C_Search from "./C_Search.vue";

  const { FETCH_FUTURE_BUDGETS, FETCH_SPECIFIC_FUTURE_BUDGET, FETCH_FUTURE_PROPOSAL_FILTER } = VUEX_ACTIONS;
  const props = defineProps({
    isoverview: Boolean,
  });
  const store = useStore();
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const futureBudget = computed(() => {
    const budget = store.getters.getFutureBudget;
    if (props.isoverview) {
      return budget.filter((item: IBudgetPlan) => item.status == "approved");
    } else {
      return budget.filter((item: IBudgetPlan) => item.status !== "approved");
    }
  });
  const filteredBudget = ref(futureBudget.value);
  const isBudgetFetched = computed(() => store.getters.getFutureBudgetFetched);
  const selectedEntity = ref<IBudgetPlan>({});
  const showDetailsModal = ref(false);
  const showDecisionModal = ref(false);
  const isApproval = ref(false);
  store.dispatch(FETCH_FUTURE_BUDGETS);

  store.dispatch(FETCH_FUTURE_PROPOSAL_FILTER);

  const financialGoalLabel = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.financial_goal_label;
    } else {
      return "Budget";
    }
  });

  const handleFutureBudgetDetails = (entity: IBudgetPlan) => {
    showDetailsModal.value = !showDetailsModal.value;
    selectedEntity.value = entity;
    store.dispatch(FETCH_SPECIFIC_FUTURE_BUDGET, selectedEntity.value.id);
  };

  const handleFutureBudgetApprove = (entity: IBudgetPlan) => {
    isApproval.value = true;
    selectedEntity.value = entity;
    showDecisionModal.value = !showDecisionModal.value;
  };

  const handleFutureBudgetDecline = (entity: IBudgetPlan) => {
    isApproval.value = false;
    selectedEntity.value = entity;
    showDecisionModal.value = !showDecisionModal.value;
  };

  const filterFutureBudgetPlans = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;

    filteredBudget.value = futureBudget.value.filter((budget: IBudgetPlan) => {
      if (budget?.entity_name) return budget?.entity_name.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });
  };

  watch([futureBudget], () => {
    filteredBudget.value = futureBudget.value;
  });
</script>
