<template>
  <div v-if="showPODetails" class="c-listpodetails__modal-overlay">
    <div class="c-listpodetails__modal-container" data-testid="list-po-details-container">
      <div class="c-listpodetails__modal-header">
        <div>
          <h2 class="c-listpodetails__modal-title" data-testid="listpo-title">{{ selectedPOName }} - Details</h2>
        </div>
        <div class="c-listpodetails__modal-header-closebtn">
          <img src="../../../public/remove.svg" alt="Remove list item button" @click="closeModal" data-testid="list-po-closemodal" />
        </div>
      </div>
      <div class="c-listpodetails__modal--details">
        <div class="c-listpodetails__modal--details-column">
          <div class="c-listpodetails__modal--details-item">
            <h3>PR Owner:</h3>
            <p>
              {{ selectedItem?.prownername ? selectedItem.prownername : "-" }}
            </p>
          </div>
          <div v-if="supplierNameEnabled" class="c-listpodetails__modal--details-item">
            <h3>Supplier Name:</h3>
            <p>
              {{ selectedItem?.suppliername ? selectedItem.suppliername : "-" }}
            </p>
          </div>
        </div>
        <div class="c-listpodetails__modal--details-column">
          <div class="c-listpodetails__modal--details-item">
            <h3>Start Date:</h3>
            <p>
              {{ selectedItem?.podate ? formatDate(selectedItem.podate) : "-" }}
            </p>
          </div>
          <div class="c-listpodetails__modal--details-item">
            <h3>Delivery Date:</h3>
            <p>
              {{ selectedItem?.deliverydate ? formatDate(selectedItem.deliverydate) : "-" }}
            </p>
          </div>
        </div>
        <div class="c-listpodetails__modal--details-column">
          <div class="c-listpodetails__modal--details-item">
            <h3>Order Amount:</h3>
            <p>
              {{ formatNumber((selectedItem.orderamount ? selectedItem.orderamount * currencyRate : 0).toFixed(2)) }}
            </p>
          </div>
          <div class="c-listpodetails__modal--details-item">
            <h3>Currency:</h3>
            <p>
              {{ selectedItem?.currency ? selectedItem.currency : "-" }}
            </p>
          </div>
        </div>
        <div class="c-listpodetails__modal--details-column">
          <div class="c-listpodetails__modal--details-item">
            <h3>Current Year:</h3>
            <p>
              {{ formatNumber(Number(selectedItem.amountCurrentYear ? selectedItem.amountCurrentYear * currencyRate : selectedItem.orderamount).toFixed(2)) }}
            </p>
          </div>
          <div class="c-listpodetails__modal--details-item">
            <h3>Next Year:</h3>
            <p>
              {{ formatNumber(Number(selectedItem.amountNextYear ? selectedItem.amountNextYear * currencyRate : 0).toFixed(2)) }}
            </p>
          </div>
        </div>
        <div class="c-listpodetails__modal--details-column">
          <div class="c-listpodetails__modal--details-item">
            <h3>Customer Contact:</h3>
            <p>
              {{ selectedItem?.customercontact ? selectedItem.customercontact : "-" }}
            </p>
          </div>
        </div>
      </div>
      <div class="c-listpodetails__modal--details">
        <div class="c-listpodetails__modal--details-comment">
          <h3>Comments:</h3>
          <p>
            {{ selectedItem?.comments ? selectedItem.comments : "-" }}
          </p>
        </div>
      </div>
      <div class="c-requestdetails__container__listitems">
        <div class="c-requestdetails__container__listitems__listpo-wrapper">
          <table class="c-requestdetails__container__listitems-table">
            <caption>
              <h3 class="c-requestdetails__container__listitems-title">List Items:</h3>
            </caption>
            <thead>
              <tr class="c-requestdetails__container__listitems-table-head--listpo">
                <th>Position:</th>
                <th>Name:</th>
                <th>Product Family:</th>
                <th>Product:</th>
                <th>Application:</th>
                <th>Project:</th>
                <th>Cost Category:</th>
                <th>Cost Type:</th>
                <th>Resource name:</th>
                <th>Person days:</th>
                <th>Amount:</th>
                <th>Current Year:</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in sortedListItems" class="c-requestdetails__container__listitems-table-row" :key="index">
                <td>{{ item.position }}</td>
                <td data-testid="list-po-name">
                  <b>{{ item.name }}</b>
                </td>
                <td>{{ item.productfamilyname }}</td>
                <td>{{ item.productname }}</td>
                <td data-testid="list-po-application">
                  {{ item.applicationname ? item.applicationname : "-" }}
                </td>
                <td>{{ item.projectname ? item.projectname : "-" }}</td>
                <td>{{ item.costtype.split(" ")[0] }}</td>
                <td>{{ item.costtype.split(" ")[1] }}</td>
                <td>{{ item.resourcename }}</td>
                <td data-testid="list-po-quantity">
                  {{ item?.quantity && item.quantity > 0 ? item.quantity : "" }}
                </td>
                <td class="c-listpodetails__modal--details-list-po-amount" data-testid="list-po-amount">
                  {{ formatNumber((item.amount ? item.amount * currencyRate : 0).toFixed(2)) + " " + currency }}
                </td>
                <td class="c-listpodetails__modal--details-list-po-amount" data-testid="list-po-currentYear">
                  {{ formatNumber(Number(item.amountCurrentYear ? item.amountCurrentYear : item.amount * currencyRate).toFixed(2)) + " " + currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed } from "vue";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { formatDate } from "../../utils/helpers/formatDate";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { IUserOrders } from "@/utils/interfaces/IPurchaseOrder";
  import { INewInvoiceListItem } from "@/utils/interfaces/IListItem";

  export default defineComponent({
    name: "ListPODetails",
    props: {
      selectedPOName: {
        type: String,
        required: true,
      },
      selectedItem: {
        type: Object as () => IUserOrders,
        required: true,
      },
      supplierNameEnabled: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const { CHANGE_PO_DETAILS_VIEW } = VUEX_ACTIONS;
      const store = useStore();
      const showPODetails = computed(() => store.getters.getShowPODetails);
      const currency = computed(() => store.getters.getSelectedCurrency);
      const currencyRate = computed(() => store.getters.getCurrencyRate);

      const closeModal = () => {
        store.dispatch(CHANGE_PO_DETAILS_VIEW, false);
      };

      const sortedListItems = computed(() => {
        return props.selectedItem?.listitems.slice().sort((a: INewInvoiceListItem, b: INewInvoiceListItem) => {
          return Number(a.position) - Number(b.position);
        });
      });

      return {
        closeModal,
        showPODetails,
        currency,
        currencyRate,
        formatNumber,
        sortedListItems,
        formatDate,
      };
    },
  });
</script>
