<template>
  <div class="v-budgetoverview__main-container" data-testid="budgetoverview-wrapper">
    <h1 class="v-polist__title-all">{{financialGoalLabel}} Overview</h1>

    <div class="v-filter-products__toggle-view--budget-overview">
      <img :src="'./grid.svg'" alt="grid view" :style="isGridView ? { border: '1px solid #525252' } : ''" @click="isGridView = !isGridView" data-testid="budgetoverview-img-grid" />
      <img :src="'./list.svg'" alt="list view" :style="!isGridView ? { border: '1px solid #525252' } : ''" @click="isGridView = !isGridView" data-testid="budgetoverview-img-list" />
    </div>

    <C_BudgetRequestReview :isoverview="true" :financialGoalLabel="financialGoalLabel" v-if="isGridView" data-testid="budgetoverview-child-budgetrequestreview" />
    <C_FutureBudgetTableOverview v-if="!isGridView" data-testid="budgetoverview-child-futurebudgetableoverview" :financialGoalLabel="financialGoalLabel" />
  </div>
</template>
<script lang="ts" setup>
  const { FETCH_USERS } = VUEX_ACTIONS;
  import C_BudgetRequestReview from "@/components/C_BudgetRequestReview.vue";
  import C_FutureBudgetTableOverview from "@/components/C_FutureBudgetTableOverview.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { ref, computed } from "vue";
  import { useStore } from "vuex";
  const store = useStore();
  const isGridView = ref(true);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);

  const financialGoalLabel = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.financial_goal_label;
    } else {
      return "Budget";
    }
  });

  store.dispatch(FETCH_USERS);
</script>
